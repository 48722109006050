import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import piano from './piano.png';

function MusicCorner() {
    const [context] = useState(new AudioContext());
    const [oszi, setOszi] = useState(null);

    const playTone = (freq) => {
        if (!oszi) {
            const oscillator = context.createOscillator();
            oscillator.frequency.value = freq;
            oscillator.connect(context.destination);
            oscillator.start(0);
            setOszi(oscillator);
        }
    };

    const stopTone = () => {
        if (oszi) {
            oszi.stop(0);
            oszi.disconnect();
            setOszi(null);
        }
    };

    return (
        <div className='generalContainer'>
            <div>
                <h2>Music Corner</h2>
                
                <div className='generalContainerNavBar'>
                    <ul>
                        <li><Link to="/">home</Link></li>
                    </ul>
                </div>

                <br/>

                <h3>Web Keyboard</h3>
                <p>
                    <a className='footerA' href="https://webaudio.github.io/web-audio-api/" target="_blank" rel="noopener noreferrer">
                        <span className='footerSpan'>[... this project use "web audio api"...]</span>
                    </a>
                </p>
                <p>work is still in progress...</p>

                <img src={piano} width="596" height="146" border="0" alt="" title="" useMap="#klavier1" />
                <map name="klavier1" id="klavier1">
                    <area shape="rect" coords="-8,0,24,145" onMouseOver={() => playTone(175)} onMouseLeave={stopTone} alt="f" title="f" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="-8,0,24,145" onMouseOver={() => playTone(175)} onMouseLeave={stopTone} alt="f" title="f" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="23,0,43,85" onMouseOver={() => playTone(185)} onMouseLeave={stopTone} alt="fis" title="fis" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="42,0,59,145" onMouseOver={() => playTone(196)} onMouseLeave={stopTone} alt="g" title="g" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="58,0,76,85" onMouseOver={() => playTone(208)} onMouseLeave={stopTone} alt="gis" title="gis" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="75,0,92,145" onMouseOver={() => playTone(220)} onMouseLeave={stopTone} alt="a" title="a" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="91,0,110,85" onMouseOver={() => playTone(233)} onMouseLeave={stopTone} alt="b" title="b" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="109,0,133,145" onMouseOver={() => playTone(246)} onMouseLeave={stopTone} alt="h" title="h" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="133,0,157,145" onMouseOver={() => playTone(261)} onMouseLeave={stopTone} alt="c1" title="c1" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="156,0,175,85" onMouseOver={() => playTone(277)} onMouseLeave={stopTone} alt="cis1" title="cis1" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="174,0,191,145" onMouseOver={() => playTone(293)} onMouseLeave={stopTone} alt="d1" title="d1" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="190,0,209,85" onMouseOver={() => playTone(311)} onMouseLeave={stopTone} alt="dis1" title="dis1" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="208,0,234,145" onMouseOver={() => playTone(330)} onMouseLeave={stopTone} alt="e1" title="e1" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="233,0,257,145" onMouseOver={() => playTone(349)} onMouseLeave={stopTone} alt="f1" title="f1" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="256,0,275,85" onMouseOver={() => playTone(370)} onMouseLeave={stopTone} alt="fis1" title="fis1" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="274,0,291,145" onMouseOver={() => playTone(392)} onMouseLeave={stopTone} alt="g1" title="g1" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="290,0,308,85" onMouseOver={() => playTone(415)} onMouseLeave={stopTone} alt="gis1" title="gis1" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="307,0,324,145" onMouseOver={() => playTone(440)} onMouseLeave={stopTone} alt="a1" title="a1" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="323,0,342,85" onMouseOver={() => playTone(466)} onMouseLeave={stopTone} alt="b1" title="b1" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="341,0,365,145" onMouseOver={() => playTone(493)} onMouseLeave={stopTone} alt="h1" title="h1" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="364,0,389,145" onMouseOver={() => playTone(523)} onMouseLeave={stopTone} alt="c2" title="c2" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="388,0,407,85" onMouseOver={() => playTone(554)} onMouseLeave={stopTone} alt="cis2" title="cis2" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="406,0,422,145" onMouseOver={() => playTone(587)} onMouseLeave={stopTone} alt="d2" title="d2" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="421,0,440,85" onMouseOver={() => playTone(622)} onMouseLeave={stopTone} alt="dis2" title="dis2" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="439,0,463,145" onMouseOver={() => playTone(659)} onMouseLeave={stopTone} alt="e2" title="e2" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="461,0,488,145" onMouseOver={() => playTone(698)} onMouseLeave={stopTone} alt="f2" title="f2" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="487,0,506,85" onMouseOver={() => playTone(740)} onMouseLeave={stopTone} alt="fis2" title="fis2" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="505,0,521,145" onMouseOver={() => playTone(784)} onMouseLeave={stopTone} alt="g2" title="g2" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="520,0,538,85" onMouseOver={() => playTone(831)} onMouseLeave={stopTone} alt="gis2" title="gis2" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="537,0,554,145" onMouseOver={() => playTone(880)} onMouseLeave={stopTone} alt="a2" title="a2" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="553,0,572,85" onMouseOver={() => playTone(932)} onMouseLeave={stopTone} alt="b2" title="b2" style={{ cursor: 'pointer' }} />
                    <area shape="rect" coords="571,0,596,145" onMouseOver={() => playTone(987)} onMouseLeave={stopTone} alt="h2" title="h2" style={{ cursor: 'pointer' }} />

                    
                   {/*  <area shape="rect" coords="133,0,157,145" onMouseOver="spielen(261,'c1');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="c1" title="c1" style="cursor:pointer;"/> hierfür schon gemacht */}

                    {/* <area shape="rect" coords="156,0,175,85" onMouseOver="spielen(277,'cis1');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="cis1" title="cis1" style="cursor:pointer;"/>
                    <area shape="rect" coords="174,0,191,145" onMouseOver="spielen(293,'d1');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="d1" title="d1" style="cursor:pointer;"/>
                    <area shape="rect" coords="190,0,209,85" onMouseOver="spielen(311,'dis1');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="dis1" title="dis1" style="cursor:pointer;"/>
                    <area shape="rect" coords="208,0,234,145" onMouseOver="spielen(330,'e1');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="e1" title="e1" style="cursor:pointer;"/>
                    <area shape="rect" coords="233,0,257,145" onMouseOver="spielen(349,'f1');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="f1" title="f1" style="cursor:pointer;"/>
                    <area shape="rect" coords="256,0,275,85" onMouseOver="spielen(370,'fis1');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="fis1" title="fis1" style="cursor:pointer;"/>
                    <area shape="rect" coords="274,0,291,145" onMouseOver="spielen(392,'g1');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="g1" title="g1" style="cursor:pointer;"/>
                    <area shape="rect" coords="290,0,308,85" onMouseOver="spielen(415,'gis1');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="gis1" title="gis1" style="cursor:pointer;"/>
                    <area shape="rect" coords="307,0,324,145" onMouseOver="spielen(440,'a1');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="a1" title="a1" style="cursor:pointer;"/>
                    <area shape="rect" coords="323,0,342,85" onMouseOver="spielen(466,'b1');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="b1" title="b1" style="cursor:pointer;"/>
                    <area shape="rect" coords="341,0,365,145" onMouseOver="spielen(493,'h1');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="h1" title="h1" style="cursor:pointer;"/>
                    <area shape="rect" coords="364,0,389,145" onMouseOver="spielen(523,'c2');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="c2" title="c2" style="cursor:pointer;"/>
                    <area shape="rect" coords="388,0,407,85" onMouseOver="spielen(554,'cis2');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="cis2" title="cis2" style="cursor:pointer;"/>
                    <area shape="rect" coords="406,0,422,145" onMouseOver="spielen(587,'d2');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="d2" title="d2" style="cursor:pointer;"/>
                    <area shape="rect" coords="421,0,440,85" onMouseOver="spielen(622,'dis2');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="dis2" title="dis2" style="cursor:pointer;"/>
                    <area shape="rect" coords="439,0,463,145" onMouseOver="spielen(659,'e2');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="e2" title="e2" style="cursor:pointer;"/>
                    <area shape="rect" coords="461,0,488,145" onMouseOver="spielen(698,'f2');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="f2" title="f2" style="cursor:pointer;"/>
                    <area shape="rect" coords="487,0,506,85" onMouseOver="spielen(740,'fis2');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="fis2" title="fis2" style="cursor:pointer;"/>
                    <area shape="rect" coords="505,0,521,145" onMouseOver="spielen(784,'g2');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="g2" title="g2" style="cursor:pointer;"/>
                    <area shape="rect" coords="520,0,538,85" onMouseOver="spielen(831,'gis2');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="gis2" title="gis2" style="cursor:pointer;"/>
                    <area shape="rect" coords="537,0,554,145" onMouseOver="spielen(880,'a2');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="a2" title="a2" style="cursor:pointer;"/>
                    <area shape="rect" coords="553,0,572,85" onMouseOver="spielen(932,'b2');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="b2" title="b2" style="cursor:pointer;"/>
                    <area shape="rect" coords="571,0,596,145" onMouseOver="spielen(987,'h2');return(true);" onMouseUp="stoppen();" onMouseOut="stoppen();" alt="h2" title="h2" style="cursor:pointer;"/>
                                 */}
                    </map>
            </div>
        </div>
    );
}

export default MusicCorner;
