import React from 'react';
import { useAuth } from '../../components/util_customHooks/useAuth';
import { Link } from 'react-router-dom';
import '../../components/util/generalStyles.css';

function UserPage() {
  const auth = useAuth();

  const handleButtonClick = () => {
    // Hier senden Sie die GET-Anfrage an die PHP-Datei im Backend
    fetch('http://localhost:8080/PHP/Simulation.php')
      .then(response => response.json())
      .then(json => {
        // Hier können Sie die Antwort verarbeiten, wenn benötigt
        console.log(json);
      })
      .catch(error => console.error(error));
  };

  return (
    <div>
      <h1>hey {auth.user}</h1>
      <button onClick={auth.logout}>Logout</button>

      <br />
      <br />
      <h2>Links</h2>
      <div className='generalContainer'>
        <div>
          <div className='generalContainerNavBar'>
            <ul>
              <li><Link to="/">home</Link></li>
              <li><Link to="/userPage/userMessages">messages</Link></li>
              <li><Link to="/impressum">impressum</Link></li>
              <li><Link to="/links">links</Link></li>
            </ul>
          </div>
        </div>
      </div>


    </div>
  );
}

export default UserPage;
