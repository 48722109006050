import { useState, useContext, createContext, useEffect } from 'react'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const token = "tokenM";

    useEffect(() => {
        const tokenValue = localStorage.getItem(token);
        if (tokenValue) {
          setIsLoggedIn(true);
        }
      }, []);
    

    const login = (user, tokenValue) => {
        setUser(user)
        console.log("user will be set: " + user);
        localStorage.setItem(token, tokenValue);
        setIsLoggedIn(true);
        console.log("user will be set: " + isLoggedIn);
    }

    const logout = () => {
        setUser(null)
        localStorage.removeItem(token);
        setIsLoggedIn(false);
    }

    const isTokenValid = () => {
        return !!localStorage.getItem(token);
    }

    return (
        <AuthContext.Provider value={{ user, login, logout, isLoggedIn, isTokenValid }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}