import React, { useState, useEffect } from 'react';
import './styles.css'; // Pfad zum CSS-Datei anpassen
import axios from 'axios';
import { HeartFill, TrashFill, Trash, Heart } from 'react-bootstrap-icons';

function MessageTile({ message, onUpdateStatus, fetchMessages   }) {
  const [backgroundColor, setBackgroundColor] = useState('');

  useEffect(() => {
    const colors = [
      '#0BA95B',
      '#7B5EA7',
      '#12B5E5',
      '#F9F4DA',
      '#ED203D',
      '#FC7428',
      '#F38BA3',
      '#FCBA28',
      '#4F9D9D',
      '#B38A58',
      '#9C79B2',
      '#D7BFD7',
      '#FF6F61',
      '#4E4A4E',
      '#C2D8B9',
      '#FFBC42',
      '#6495ED',
      '#7B68EE'
    ];

    // Zufällige Farbe auswählen
    const randomColor = colors[Math.floor(Math.random() * colors.length)];

    setBackgroundColor(randomColor);
  }, []); // Leeres Array als zweites Argument stellt sicher, dass der Effekt nur einmalig ausgeführt wird

  const tileStyle = {
    backgroundColor: backgroundColor,
  };

 

  const handleDelete = async () => {
    const confirmDelete = window.confirm("Do you really want to delete this message?");
    if (confirmDelete) {
      try {
        //const response = await axios.delete('http://localhost:8080/api/messageAPI/DeleteMessage.php', {
        const response = await axios.delete('https://malteklaes.de/backend/api/messageAPI/DeleteMessage.php', {
          data: { messageId: message.cid } // send msg id in body
        });
        fetchMessages(); // update msgs without this msg
      } catch (error) {
        console.error('Error deleting message:', error);
      }
    }
  };
  

  const handleLike = () => {
    var isFav = (message.status == 0) ? 1 : 0;
    onUpdateStatus(message.cid, isFav); // Aktualisieren des "status" der Nachricht
    console.log("Like bei: " + message.cid);
  };

  return (
    <div className="messageTile" style={tileStyle}>
      <div className="sender">[from: {message.sender}]</div>
      <div className="subject">about: {message.subject}</div>
      <div className="content">„ {message.content} “</div>
      <div className="contactTime">{message.contactTime}</div>
      <br/>
      <br/>
      <div className="tileButtons">
        <div className="buttonContainer">
          <TrashFill size={25} className="deleteIcon" onClick={handleDelete} />
        </div>
        <div className="buttonContainer">
          {(message.status == 1) ?
            <HeartFill size={25} className="favoriteIcon heartIconRed" onClick={handleLike} /> :
            <Heart size={25} className="favoriteIcon" onClick={handleLike} />
          }
        </div>
      </div>
    </div>
  );
}

export default MessageTile;
