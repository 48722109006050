import React, { useState } from 'react';
import './styles.css';
import logo from '../util/logos/github.png';

function Footer() {
    const [currentTime, setCurrentTime] = useState(new Date());

    return (
        <div className='footerContainer'>
            <p>@ {currentTime.getFullYear()}</p>
            <a className='footerA' href="https://github.com/malteklaes" target="_blank" rel="noopener noreferrer">
                <img src={logo} alt="Logo" width="20" height="20"/>
                <span className='footerSpan'>malteklaes</span>
            </a>
        </div>
    );
}

export default Footer;