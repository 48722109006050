import React, { useState } from 'react';
import axios from 'axios';
import './styles.css';
import '../util/generalStyles.css';

function ContactForm() {
    const [sender, setSender] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [warningMessage, setWarningMessage] = useState('');

    const handleSend = () => {
        // Überprüfen, ob alle Felder ausgefüllt sind
        if (!sender || !subject || !content) {
            let missingFields = [];
            if (!sender) missingFields.push('sender');
            if (!subject) missingFields.push('subject');
            if (!content) missingFields.push('content');
            setWarningMessage(`Error: Please fill out one or multiple of these fields: ${missingFields.join(', ')}`);
            setTimeout(() => {
                setWarningMessage('');
            }, 4500);
            return;
        }

        // Daten vorbereiten, um an den Server zu senden
        const data = { sender, subject, content };

        // Anfrage an den Server senden
        //axios.post('http://localhost:8080/api/contactMe.php', data)
        axios.post('https://malteklaes.de/backend/api/contactMe.php', data)
            .then(response => {
                // Erfolgshinweis anzeigen
                setSuccessMessage('send successful');
                setErrorMessage('');
                setWarningMessage('');
                // Alle Felder leeren nach 1 Sekunde
                setTimeout(() => {
                    setSender('');
                    setSubject('');
                    setContent('');
                    setSuccessMessage('');
                }, 1000);
            })
            .catch(error => {
                console.error('Fehler beim Senden der Daten:', error);
                // Fehlermeldung anzeigen
                setErrorMessage('send not successful');
                setSuccessMessage('');
                setWarningMessage('');
            });
    };

    const handleCheckEmail = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(sender)) {
            setErrorMessage('Error: Please enter a proper email address as sender like example@gmail.com');
            setTimeout(() => {
                setWarningMessage('');
                setErrorMessage('');
                setSuccessMessage('');
            }, 4500);
            return;
        }
        setErrorMessage('');
        handleSend(); // Wenn E-Mail gültig ist, sende die Nachricht
    };

    const handleQuit = () => {
        // Alle Felder leeren
        setSender('');
        setSubject('');
        setContent('');
        setSuccessMessage('');
        setErrorMessage('');
        setWarningMessage('');
    };

    return (
        <div>
            <div className='generalContainerContactForm'>

            <div className='container'>
                <h2>contact me here...</h2>
                <p>...or text me directly to <u><b>mail@malteklaes.de</b></u></p>
                <div className="inputContainer">
                    <input
                        type="text"
                        placeholder="from / absender"
                        value={sender}
                        onChange={e => setSender(e.target.value)}
                        />
                </div>
                <div className="inputContainer">
                    <input
                        type="text"
                        placeholder="subject / betreff"
                        value={subject}
                        onChange={e => setSubject(e.target.value)}
                        />
                </div>
                <div className="contentContainer">
                <textarea
                    className="content-textarea"
                    placeholder="msg... / nachricht..."
                    value={content}
                    onChange={e => setContent(e.target.value)}
                    />
                </div>
                <div className='buttonContact'>
                    <button onClick={handleSend}><b>send</b> normal msg</button>
                    <button onClick={handleCheckEmail}><b>send</b> as email</button>
                    <button onClick={handleQuit}>cancel</button>
                </div>
                {successMessage && <div className='successMsg'>{successMessage}</div>}
                {errorMessage && <div className='errorMsg'>{errorMessage}</div>}
                {warningMessage && <div className='warningMsg'>{warningMessage}</div>}
             </div>
            </div>
        </div>
    );
}

export default ContactForm;
