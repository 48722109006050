import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Slider from '@mui/material/Slider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MessageTile from '../../../components/messageTile/MessageTile';

function UserMessages() {
  const [messages, setMessages] = useState([]);
  const [filteredMessages, setFilteredMessages] = useState([]); // Hier hinzugefügt
  const [searchTerm, setSearchTerm] = useState('');
  const [searchOptions, setSearchOptions] = useState({
    sender: true,
    subject: true,
    content: true,
  }); // Default-Suchoptionen
  const [dateRange, setDateRange] = useState([new Date(), new Date()]); // Default-Zeitbereich
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [step, setStep] = useState(1); // Default-Schrittgröße in Tagen


  const [showLikesOnly, setShowLikesOnly] = useState(false);

  useEffect(() => {
    fetchMessages();
  }, []);

  useEffect(() => {
    filterMessages();
  }, [messages, searchTerm, searchOptions, dateRange]);

  const fetchMessages = async () => {
    try {
      //const response = await axios.get('http://localhost:8080/api/messageAPI/GetMessages.php');
      const response = await axios.get('https://malteklaes.de/backend/api/messageAPI/GetMessages.php');
      setMessages(response.data);
      console.log("DATA: " + JSON.stringify(response.data[0]));
      
      // Min- und Max-Datum erhalten
      const dates = response.data.map(message => new Date(message.contactTime));
      const min = new Date(Math.min(...dates));
      const max = new Date(Math.max(...dates));

      // Anzahl der Tage zwischen min und max berechnen
      const diffTime = max.getTime() - min.getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      // Anfangswerte setzen
      setMinDate(min);
      setMaxDate(max);
      setDateRange([min, max]);
      setStep(diffDays);
    } catch (error) {
      console.error('Error fetching messages:', error);
    }
  };

  const filterMessages = (isChecked) => {
    // Filter nach Suchbegriff und Suchoptionen
    const filteredBySearchTerm = messages.filter(message => {
      console.log("MESSAGE: " + message.status );
      if (searchOptions.sender && message.sender.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }
      if (searchOptions.subject && message.subject.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }
      if (searchOptions.content && message.content.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }
      return false;
    });

    // Filter nach Zeitbereich
    const filteredByDateRange = filteredBySearchTerm.filter(message => {
      const messageDate = new Date(message.contactTime);
      return messageDate >= dateRange[0] && messageDate <= dateRange[1];
    });
    

    // Filter nach Likes
    const filteredByLikes = isChecked ? filteredByDateRange.filter(message => {
      return (message.status === "1");
    }) : filteredByDateRange;

    console.log("FILTERED: " + filteredByLikes.length);

    setFilteredMessages(filteredByLikes);
  };
  

  const handleSearchTermChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleSearchOptionChange = event => {
    setSearchOptions({ ...searchOptions, [event.target.name]: event.target.checked });
  };

  const formatDate = (date) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  };

  const updateMessageStatus = async (id, isFavorite) => {
    try {
      console.log("CID: " + id);
      const formData = new FormData();
      formData.append('messageId', id);
      formData.append('isFavorite', isFavorite ? 1 : 0); // Konvertiert den Boolean-Wert in eine Zahl (1 oder 0)
      //const response = await axios.post('http://localhost:8080/api/messageAPI/UpdateMessageStatus.php', formData);
      const response = await axios.post('https://malteklaes.de/backend/api/messageAPI/UpdateMessageStatus.php', formData);
      console.log('Message status updated successfully: ', response.data);
      fetchMessages(); // Aktualisiere die Nachrichtenliste nach der Statusänderung
    } catch (error) {
      console.error('Error updating message status: ', error);
    }
    filterMessages();
  };

  const handleLikesFilterChange = (event) => {
    setShowLikesOnly(event.target.checked);
  };

  const shipping = (e) => {
    console.log("SHIPPING: " + e.target.checked);
    setShowLikesOnly(e.target.checked);
    filterMessages(e.target.checked);
  }
  
  

  return (
    <div className="messageContainer">
      <h1>messages</h1>
      <div className='generalContainer'>
        <div>
          <div className='generalContainerNavBar'>
            <ul>
              <li><Link to="/">home</Link></li>
              <li><Link to="/userPage">main</Link></li>
            </ul>
          </div>
        </div>
        <br />
        <div className='generalContainer'>
          <input className='searchContainer' type="text" placeholder="search..." value={searchTerm} onChange={handleSearchTermChange} />
        </div>
        <div className='boxContainer'>
          <FormControlLabel
            control={<Checkbox checked={searchOptions.sender} onChange={handleSearchOptionChange} name="sender" />}
            label="sender"
          />
          <FormControlLabel
            control={<Checkbox checked={searchOptions.subject} onChange={handleSearchOptionChange} name="subject" />}
            label="subject"
          />
          <FormControlLabel
            control={<Checkbox checked={searchOptions.content} onChange={handleSearchOptionChange} name="content" />}
            label="content"
          />

<div className='boxContainer'>
  <FormControlLabel
    control={<Checkbox checked={showLikesOnly} onChange={shipping} name="showLikesOnly" />}
    label="Show Likes Only"
  />
</div>



        </div>
        <br />
        <div className='generalContainer'>
          <Slider
            min={minDate.getTime()}
            max={maxDate.getTime()}
            value={[dateRange[0].getTime(), dateRange[1].getTime()]}
            onChange={(event, newValue) => setDateRange([new Date(newValue[0]), new Date(newValue[1])])}
            valueLabelDisplay="auto"
            aria-label="Zeitbereich"
            style={{ width: '60%' }}
            step={step} // Schrittgröße in Tagen
            marks={[
              { value: minDate.getTime(), label: formatDate(minDate) },
              { value: maxDate.getTime(), label: formatDate(maxDate) }
            ]}
            valueLabelFormat={(value) => formatDate(new Date(value))}
          />
        </div>
        <br />
        <div>
          {filteredMessages.map((message, index) => (
            <MessageTile key={index} message={message} onUpdateStatus={updateMessageStatus} fetchMessages={fetchMessages} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserMessages;

