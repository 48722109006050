import React, { useState, useEffect } from 'react';

// Child-Komponente für die Anzeige von Datum und Uhrzeit
function DateTimeDisplay() {
    // Zustand für die aktuelle Uhrzeit
    const [currentTime, setCurrentTime] = useState(new Date());

    // Aktuelle Uhrzeit sekündlich aktualisieren
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        // Aufräumen, wenn die Komponente unmontiert wird
        return () => clearInterval(intervalId);
    }, []); // Leeres Array als zweites Argument, um den Effekt nur einmalig beim Mounten auszuführen

    // Aktuelles Datum und Uhrzeit formatieren
    const day = currentTime.getDate();
    const month = currentTime.getMonth() + 1; // Monat ist nullbasiert
    const year = currentTime.getFullYear();
    const formattedDate = `${day}|${month}|${year}`;
    //const formattedDate = currentTime.toLocaleDateString('de-DE');

    const formattedTime = currentTime.toLocaleTimeString('de-DE');

    return (
        <>
            <b>{formattedDate}</b> at  <b>{formattedTime}</b>
        </>
    );
}

export default DateTimeDisplay;