import './App.css';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import PrivateRoutes from './components/util/PrivateRoutes';

import { AuthProvider } from './components/util_customHooks/useAuth';
import Home from './pages/Home'

import Greeter from './components/greeterComponent/Greeter';
import Footer from './components/footer/Footer';
import UserPage from './pages/userPage/UserPage';
import Links from './pages/links/Links';
import Project from './pages/projects/Project';
import RandomMazeGenerator from './pages/projects/projectPanels/RandomMazeGenerator/RandomMazeGenerator';
import UserMessages from './pages/userPage/userComponents/UserMessages'
import Impressum from './components/footer/Impressum'
import MusicCorner from './pages/projects/projectPanels/musicCorner/MusicCorner';


function App() {

  return (
    <div className="App">
      <AuthProvider>

        <BrowserRouter>
          {/* --- General Components --- */}
          <Greeter />

          
         

          <Routes>
          {/* --- General Routes --- */}
            <Route path='/impressum' element={<Impressum/>} />
            <Route path='/' element={<Home/>} />

            <Route path='/randomMazeGenerator' element={<RandomMazeGenerator/>} />
            <Route path='/musicCorner' element={<MusicCorner/>} />
          {/* --- Protected Routes --- */}
            <Route 
                path='/userPage' 
                element={
                  <PrivateRoutes>
                    <UserPage />
                  </PrivateRoutes>
              } 
            />
            <Route path='/links' element={<PrivateRoutes><Links /></PrivateRoutes>}/>
            <Route path='/userPage/userMessages' element={<PrivateRoutes><UserMessages /></PrivateRoutes>}/>
            <Route path='/projects' element={<Project />}/>

          </Routes>

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <Footer />
    
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
