import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../util_customHooks/useAuth';


const PrivateRoutes = ({ children }) => {
    const location = useLocation();
    const auth = useAuth();


    if(!auth.isTokenValid()) {
        return <Navigate to='/' state={{ path: location.pathname }} />
    }

    return children
}

export default PrivateRoutes 