import React from 'react';
import './styles.css';
import '../util/generalStyles.css'
import { Link } from 'react-router-dom';
import logo from '../util/logos/github.png';


function Impressum() {

    return (
        <div>
            <h3>Impressum</h3>
            <p>Malte Klaes</p>
            <p>mail@malteklaes.de</p>
            <p>
                <a className='footerA' href="https://github.com/malteklaes" target="_blank" rel="noopener noreferrer">
                    <img src={logo} alt="Logo" width="20" height="20"/>
                    <span className='footerSpan'>malteklaes</span>
                </a>
            </p>
            <div className='generalContainer'>
                <div>
                    <div className='generalContainerNavBar'>
                        <ul>
                            <li><Link to="/">home</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>
    );
}

export default Impressum;