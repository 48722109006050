import React, { useState, useEffect } from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import './styles.css';

function Header() {
    // Array von harmlosen Namen
    //const characterisationsHarmlos = ["stranger", "mensch", "commander", "username","Captain Future", "Odysseus", "Lee Monade" , "Bob", "Alice", "Rainer Wein" , "Schakeline" , "Anna Gramm" , "Frau Holle" , "Hans Wurst" , "co-pilot" , "niemand" , "Klaus Uhr", "Sundance Kid", "Wyatt Earp", "Spike", "Jet", "Faye Valentine", "Ed", "Clint Eastwood", "Buffalo Bill"];
    const characterisationsHarmloser = ["stranger", "mensch", "commander", "username","Captain Future", "Odysseus", "Lee Monade" , "Bob", "Alice", "Rainer Wein" , "Anna Gramm" , "Frau Holle" , "co-pilot" , "niemand" , "Klaus Uhr", "Sundance Kid", "Wyatt Earp", "Spike", "Jet", "Faye Valentine", "Ed", "Clint Eastwood", "Buffalo Bill"];


    // Zufälligen Namen aus dem Array auswählen
    const randomName = characterisationsHarmloser[Math.floor(Math.random() * characterisationsHarmloser.length)];

    return (
        <div className='containerHeader'>

            <div className='greeterHeader'>
                <p>hey {randomName} ... on <DateTimeDisplay /></p>
            </div>
        </div>
    );
}

export default Header;
