import React from 'react';
import { Link } from 'react-router-dom';
import '../../components/util/generalStyles.css'
import './styles.css';

function Projects() {

    return (
        


        <div className='generalProjects'>
            <div className="container">
                <div className='generalContainer'>
                    <h2>projects</h2>
                    <ul>
                        <li><Link to="/randomMazeGenerator">maze generator</Link></li>
                        <li><Link to="/musicCorner">music corner</Link></li>
                    </ul>
                
                </div>
            </div>
        </div>

    );
}

export default Projects;