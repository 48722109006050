import React from 'react';
import Login from '../components/login/Login';
import ContactForm from '../components/contactForm/ContactForm';
import '../components/util/generalStyles.css';
import Links from '../pages/links/Links'
import Project from '../pages/projects/Project'

function Home() {

    return (
        <div className='generalContainer'>
            <ContactForm />
            <Login />
            <Links />
            <Project />
        </div>
    );
}

export default Home;