import React, { useState } from 'react';
import axios from 'axios';
import './styles.css';
import '../util/generalStyles.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../util_customHooks/useAuth'


function Login() {
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const auth = useAuth();
    const navigate = useNavigate();
    //const location = useLocation();
    //const redirectPath = location.state?.path || '/'

    const handleLogin = () => {
        const data = { user, pwd };

        //axios.post('http://localhost:8080/api/loginChecker.php', data)
        axios.post('https://malteklaes.de/backend/api/LoginChecker.php', data)
            .then(response => {
                if (response.data.success) {
                    console.log("Login successful");
                    //onLoginSuccess(response.data.token);

                    auth.login(user, response.data.token);
                    //navigate(redirectPath, { replace: true });
                    navigate("/userPage", { replace: true });
                } else {
                    console.log("Login failed");
                    setErrorMessage('Error: Login failed');
                    setTimeout(() => {
                        setErrorMessage('');
                    }, 1500);
                }
            })
            .catch(error => {
                console.error("Login error:", error);
                setErrorMessage('Login fehlgeschlagen');
                setTimeout(() => {
                    setErrorMessage('');
                }, 2500);
            });
    };

    const handleClear = () => {
        setUser('');
        setPwd('');
        setErrorMessage('');
    };

    return (
        <div>
            <div className='generalLogin'>
                <div className="container">
                
                <h2>login</h2>
                <div className="inputContainer">

                    <input 
                        type="text" 
                        placeholder="name" 
                        value={user} 
                        onChange={e => setUser(e.target.value)} 
                        />
                        </div>
                        <div className="inputContainer">

                    <input 
                        type="password" 
                        placeholder="pwd" 
                        value={pwd} 
                        onChange={e => setPwd(e.target.value)} 
                        />
                        </div>
                    <div>
                        <button onClick={handleLogin}>login</button>
                        <button onClick={handleClear}>clear</button>
                    </div>
                    {errorMessage && <div className="errorMsg">{errorMessage}</div>}
            
                </div>
            </div>
        </div>
    );
}

export default Login;
