import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../components/util/logos/github.png';
import '../../components/util/generalStyles.css'
import './styles.css';

function Links() {

    return (
        


        <div className='generalLinks'>
            <div className="container">
                <div className='generalContainer'>
                    <h2>links</h2>
                    <ul>
                        <li><Link to="/">home</Link></li>
                        <li><Link to="/impressum">impressum</Link></li>
                        <li><Link to="/userPage">own userspace</Link></li>
                        <li><Link to="/userPage/userMessages">messages</Link></li>
                        <li>
                        <a className='footerA' href="https://github.com/malteklaes" target="_blank" rel="noopener noreferrer">
                            <img src={logo} alt="Logo" width="20" height="20"/>
                            <span className='footerSpan'>malteklaes</span>
                        </a>
                        </li>
                    </ul>
                
                </div>
            </div>
        </div>

    );
}

export default Links;