import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../../components/util/logos/github.png';
import '../../../../components/util/generalStyles.css';
import './styles.css';
import Slider from '@mui/material/Slider';

function RandomMazeGenerator() {
    const [n, setN] = useState(20);
    const [valueOfButton, setValueOfButton] = useState("");
    const factor = 40;
    const NORTH = 0;
    const EAST = 1;
    const SOUTH = 2;
    const WEST = 3;
    let north, east, south, west, visited;
    let timeoutID; // global variable for saving the timeout-ID
    const canvasRef = useRef(null);

    useEffect(() => {
        setupCanvas();
        init();
        generate(1, 1);
        drawMaze();
    }, [n]);

    const setupCanvas = () => {
        const mazeCanvas = canvasRef.current;
        mazeCanvas.width = (n + 2) * factor;
        mazeCanvas.height = (n + 2) * factor;
    }

    const setup = () => {
        setupCanvas();
        init();
        generate(1, 1);
        drawMaze();
    }

    const init = () => {
        visited = new Array(n + 2).fill(null).map(() => new Array(n + 2).fill(false));
        for (let x = 0; x < n + 2; x++) {
            visited[x][0] = true;
            visited[x][n + 1] = true;
        }
        for (let y = 0; y < n + 2; y++) {
            visited[0][y] = true;
            visited[n + 1][y] = true;
        }

        north = new Array(n + 2).fill(null).map(() => new Array(n + 2).fill(true));
        east = new Array(n + 2).fill(null).map(() => new Array(n + 2).fill(true));
        south = new Array(n + 2).fill(null).map(() => new Array(n + 2).fill(true));
        west = new Array(n + 2).fill(null).map(() => new Array(n + 2).fill(true));
    }

    function drawMaze() {
        const ctx = document.getElementById("mazeCanvas").getContext("2d");
        north[1][1] = false;
        south[n][n] = false;
        ctx.strokeStyle = "orangered";
        ctx.lineWidth = 5;
        for (let x = 1; x <= n; x++) {
            for (let y = 1; y <= n; y++) {
                if (north[x][y]) {
                    ctx.beginPath();
                    ctx.moveTo(x * factor, y * factor);
                    ctx.lineTo((x + 1) * factor, y * factor);
                    ctx.stroke();
                }
                if (south[x][y]) {
                    ctx.beginPath();
                    ctx.moveTo(x * factor, (y + 1) * factor);
                    ctx.lineTo((x + 1) * factor, (y + 1) * factor);
                    ctx.stroke();
                }
                if (west[x][y]) {
                    ctx.beginPath();
                    ctx.moveTo(x * factor, y * factor);
                    ctx.lineTo(x * factor, (y + 1) * factor);
                    ctx.stroke();
                }
                if (east[x][y]) {
                    ctx.beginPath();
                    ctx.moveTo((x + 1) * factor, y * factor);
                    ctx.lineTo((x + 1) * factor, (y + 1) * factor);
                    ctx.stroke();
                }
            }
        }
    }

    const naivPathFinder = () => {
        const path = [];
        const visited = new Array(n + 2).fill(null).map(() => new Array(n + 2).fill(false));

        function explore(x, y) {
            if (x === n && y === n) {
                path.push([x, y]);
                return true;
            }

            visited[x][y] = true;

            const neighbors = [];
            if (!south[x][y] && !visited[x][y + 1]) neighbors.push([x, y + 1]); // South
            if (!east[x][y] && !visited[x + 1][y]) neighbors.push([x + 1, y]); // East
            if (!west[x][y] && !visited[x - 1][y]) neighbors.push([x - 1, y]); // West
            if (!north[x][y] && !visited[x][y - 1]) neighbors.push([x, y - 1]); // North

            if (neighbors.length === 0) {
                return false;
            }

            for (const [nx, ny] of neighbors) {
                if (explore(nx, ny)) {
                    path.push([x, y]);
                    return true;
                }
            }

            return false;
        }

        explore(1, 1);
        return path.reverse();
    }

    const drawSolution = (solutionPath, speed, color) => {
        const ctx = canvasRef.current.getContext('2d');
        //ctx.strokeStyle = "#0BA95B";
        ctx.strokeStyle = color;
        ctx.lineWidth = 20;
        ctx.beginPath();
        const step = 1;
        let currentStep = 0;

        function drawStep() {
            if (currentStep >= solutionPath.length) return;
            const [x, y] = solutionPath[currentStep];
            ctx.lineTo(x * factor + factor / 2, y * factor + factor / 2);
            ctx.stroke();
            currentStep += step;
            timeoutID = setTimeout(drawStep, speed);
        }

        ctx.moveTo(solutionPath[0][0] * factor + factor / 2, solutionPath[0][1] * factor + factor / 2);
        drawStep();
    }

    const resetMaze = () => {
        clearTimeout(timeoutID);
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, (n + 2) * factor, (n + 2) * factor);
        drawMaze();
    }

    const generate = (x, y) => {
        visited[x][y] = true;
        while (!visited[x][y + 1] || !visited[x + 1][y] || !visited[x][y - 1] || !visited[x - 1][y]) {
            let r = Math.floor(Math.random() * 4);
            if (r === NORTH && !visited[x][y - 1]) {
                north[x][y] = false;
                south[x][y - 1] = false;
                generate(x, y - 1);
            } else if (r === EAST && !visited[x + 1][y]) {
                east[x][y] = false;
                west[x + 1][y] = false;
                generate(x + 1, y);
            } else if (r === SOUTH && !visited[x][y + 1]) {
                south[x][y] = false;
                north[x][y + 1] = false;
                generate(x, y + 1);
            } else if (r === WEST && !visited[x - 1][y]) {
                west[x][y] = false;
                east[x - 1][y] = false;
                generate(x - 1, y);
            }
        }
    }

    const raceMaze = (level) => {
        
        const solutionPath = naivPathFinder();
        clearTimeout(timeoutID);
        switch (level) {
            case "puppy":
                drawSolution(solutionPath, 500, levelColors.puppy);
                break;
            case "medium":
                drawSolution(solutionPath, 200, levelColors.medium);
                break;
            case "hard":
                drawSolution(solutionPath, 100, levelColors.hard);
                break;
            case "nightmare":
                drawSolution(solutionPath, 50, levelColors.nightmare);
                break;
            case "show result":
                drawSolution(solutionPath, 1, levelColors.showresult);
                break;
            default:
                drawSolution(solutionPath, 1, "#fff");
                break;
        }
    }

    let textColor;
    if (n >= 2 && n <= 33) {
        textColor = '#0BA95B'; // green
    } else if (n >= 34 && n <= 66) {
        textColor = '#FCBA28'; // yellow
    } else if (n >= 67 && n <= 100) {
        textColor = '#ED203D'; // red
    }


    const levelColors = {
        puppy: "#0BA95B",
        medium: "#FCBA28",
        hard: "#ED203D",
        nightmare: "#7B5EA7",
        showresult: "#0ed471",
      };

      
   

 

    return (
        <div className='generalContainer'>
            <div>
                <h2>Random Maze Generator</h2>
                <p>
                    <a className='footerA' href="https://github.com/malteklaes/ProcessingMazeGenerator" target="_blank" rel="noopener noreferrer">
                        <img src={logo} alt="Logo" width="20" height="20"/>
                        <span className='footerSpan'>(ProcessingMazeGenerator)</span>
                    </a>
                </p>
                <div className='generalContainerNavBar'>
                    <ul>
                        <li><Link to="/">home</Link></li>
                    </ul>
                </div>
                <br/>
                <label htmlFor="sizeInput">Maze complexity [<b style={{ color: textColor }}>{n}%</b>]: </label>
                <Slider
                    min={2}
                    max={100}
                    value={n}
                    onChange={(e, value) => setN(value)}
                    valueLabelDisplay="auto"
                    aria-label="Maze Size"
                    style={{ width: '60%' }}
                />
                <button onClick={setup}>generate maze</button>
                
                <br/>
                <canvas id="mazeCanvas" ref={canvasRef} className="mazeCanvas"></canvas><br />
                
                <div>
                <h3 id="mazeTitle">Race the maze... with your eyes <br/><br/></h3>
                <br/>
                <button onClick={() => {
                    document.getElementById('mazeTitle').innerHTML = "Race the maze... with your eyes  <br/><br/>";
                    resetMaze();
                }}><b>reset</b> maze</button>
                <br/>
                <button onClick={() => {
                    document.getElementById('mazeTitle').innerHTML = "Race the maze... with your eyes <br/> <b style='color: #0BA95B;'>puppy</b>";
                    raceMaze('puppy');
                }}>Level: <b style={{ color: '#0BA95B' }}>puppy</b></button>
                <br/>
                <button onClick={() => {
                    document.getElementById('mazeTitle').innerHTML = "Race the maze... with your eyes <br/> <b style='color: #FCBA28;'>medium</b>";
                    raceMaze('medium');
                }}>Level: <b style={{ color: '#FCBA28' }}>medium</b></button>
                <br/>
                <button onClick={() => {
                    document.getElementById('mazeTitle').innerHTML = "Race the maze... with your eyes <br/> <b style='color: #ED203D;'>hard</b>";
                    raceMaze('hard');
                }}>Level: <b style={{ color: '#ED203D' }}>hard</b></button>
                <br/>
                <button onClick={() => {
                    document.getElementById('mazeTitle').innerHTML = "Race the maze... with your eyes <br/> <b style='color: #7B5EA7;'>nightmare</b>";
                    raceMaze('nightmare');
                }}>Level: <b style={{ color: '#7B5EA7' }}>nightmare</b></button>
                <br/>
                <button onClick={() => {
                    document.getElementById('mazeTitle').innerHTML = "Race the maze... with your eyes <br/> <b style='color: #0ed471;'>show result</b>";
                    raceMaze('show result');
                }}><b>show</b> result</button>
                <br/>
                
                <br/>
                </div>
            </div>
        </div>
    );
}

export default RandomMazeGenerator;
